<template>
  <b-row>
    <!-- <IndexPurchaseImageModel ref="IndexPurchaseImageModel" /> -->
    <modal-user-wallpaper-history ref="refModalUserWallpaperHistory" />

    <b-col sm="12">
      <!-- <TableDataServerSide
        :ref="`tableUserInfoPurchaseImage`"
        :url="_url"
        @clickView="$refs.IndexPurchaseImageModel.show($event)"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/admin/userTransactionData', $event)"
        :columns="_columns"
        :newAble="false"
        viewAble
        :editAble="false"
        :deleteAble="false"
      /> -->
      <TableDataServerSide
        :ref="`tableUserInfoPurchaseImage`"
        :url="_url"
        @clickView="row => $refs.refModalUserWallpaperHistory.show(row)"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/admin/userTransactionData', $event)"
        :columns="_columns"
        :newAble="false"
        viewAble
        :editAble="false"
        :deleteAble="false"
      />
    </b-col>
  </b-row>
</template>

<script>
import ModalUserWallpaperHistory from '../../../userManagement/components/v2/ModalUserWallpaperHistory.vue'

export default {
  name: 'IndexPurchaseImageTable',
  components: {
    IndexPurchaseImageModel: () => import('./IndexPurchaseImageTable/IndexPurchaseImageModel'),
    ModalUserWallpaperHistory,
  },
  props: {
    url: {
      type: String,
      default: '/api/admin/userTransactionData?filter=purchaseImage',
    },
  },
  data() {
    return {
      filterData: 'purchaseImage',
      results: [],
    }
  },
  computed: {
    _url() {
      return this.url
    },
    _role() {
      return this.role.role
    },

    _columns() {
      let columns = [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'user_as_email',
        },
        {
          label: 'ชื่อ ที่สร้าง',
          field: 'image_data_transaction_data_as_text',
        },
        {
          label: 'รูปหลัก',
          field: 'topic_datas_as_name',
        },
        {
          label: 'เครดิตเดิม',
          field: 'transaction_data_as_credit_balance',
          type: 'number',
        },
        {
          label: 'เครดิตลด',
          field: 'transaction_data_as_credit_amount',
          type: 'number',
        },
        {
          label: 'เครดิตคงเหลือ',
          field: 'transaction_data_as_credit_total',
          type: 'number',
        },
        {
          label: 'วันที่',
          field: 'created_at',
          formatFn: value => this.$date(value).format('YYYY-MM-DD HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ]

      columns.push({
        label: '',
        field: 'action',
      })
      return columns
    },
  },
  methods: {},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
